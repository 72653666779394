<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="entities"
            class="elevation-1"
            :footer-props="{ 'itemsPerPageOptions': [50, 100, 200, -1] }"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ $t('src\\views\\bookings\\needrefund.3723') }}</v-toolbar-title>
                    <v-spacer />
                </v-toolbar>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
                {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
            </template>
            <template v-slot:[`item.trip`]="{ item }">
                {{ item.trip ? item.trip.name : '' }}
            </template>
            <template v-slot:[`item.organizer`]="{ item }">
                {{ item.trip && item.trip.user ? item.trip.user.firstname : '' }} {{ item.trip && item.trip.user ? item.trip.user.lastname : '' }}
            </template>
            <template v-slot:[`item.user`]="{ item }">
                {{ item.user ? item.user.firstname : '' }} {{ item.user ? item.user.lastname : '' }}
            </template>
            <template v-slot:[`item.dates`]="{ item }">
                {{ item.start | moment('DD.MM.YY') }} - {{ item.finish | moment('DD.MM.YY') }}
            </template>
            <template v-slot:[`item.price`]="{ item }">
                {{ item.price }} <span v-html="currency(item.currency)"/>
            </template>
            <template v-slot:[`item.paid`]="{ item }">
                {{ paid(item) }} <span v-html="currency(item.currency)"/>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                {{ $t(BOOKING_STATUS_TITLE[item.status]) }}
            </template>
            <template v-slot:[`item.edit`]="{ item }">
                <v-icon color="primary" @click="openRefundModal(item)">mdi-arrow-u-left-top</v-icon>
            </template>
        </v-data-table>
    
        <v-dialog v-model="refundModal" width="600">
            <v-card class="base-alert-dialog" >
                <v-card-title class="headline"></v-card-title>
                <v-card-text class="base-alert-dialog__text">
                    <v-text-field
                        v-model="refundObject.amount"
                        :label="$t('src\\views\\bookings\\needrefund.5105')"
                        outlined
                    />
                    <v-textarea
                        v-model="refundObject.comment"
                        :label="$t('src\\views\\bookings\\needrefund.5646')"
                        outlined
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="refund" class="mb-4" color="error">
                        {{ $t('src\\views\\bookings\\needrefund.7642') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="refundModal = false" class="mb-4" color="gray">
                        {{ $t('src\\views\\bookings\\needrefund.1030') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { BOOKING_STATUS_TITLE, PAYMENT_STATUS } from '@/vars';

    export default {
        name: 'BookingsList',
        components: {
        },
        data: () => ({
            BOOKING_STATUS_TITLE,
            headers: [
                { text: 'Дата создания', value: 'createdAt' },
                { text: 'Тур', value: 'trip' },
                { text: 'Организатор', value: 'organizer' },
                { text: 'Путешественник', value: 'user' },
                { text: 'Заезд', value: 'dates' },
                { text: 'Стоимость', value: 'price' },
                { text: 'Оплачено', value: 'paid' },
                { text: 'Статус', value: 'status' },
                // { text: '', align: 'right', value: 'remove' },
                { text: '', align: 'right', value: 'edit' },
            ],
            refundModal: false,
            refundObject: {
                id: null,
                amount: 0,
                comment: ''
            }
        }),
        computed: {
            ...mapState('bookings', ['entities']),
        },
        async mounted() {
            await store.dispatch('bookings/fetchNeedRefund', {});
        },
        methods: {
            currency (value) {
                return {
                    'EUR': '&#8364;', 
                    'USD': '&#36;',
                    'RUB': '&#8381;',
                }[value]
            },
            paid(item) {
                return item.payments.reduce((sum, payment) => {
                    return sum + (payment.status === PAYMENT_STATUS.SUCCESS ? (payment.amount - payment.amountRefund) : 0);
                }, 0);
            },
            openRefundModal(item) {
                this.refundObject = {
                    id: item.id,
                    amount: this.paid(item),
                    comment: ''
                };
                this.refundModal = true;
            },
            async refund() {
                await store.dispatch('bookings/refund', this.refundObject);
                await store.dispatch('bookings/fetchNeedRefund', {});
                this.refundModal = false;
            }
        }
    }
</script>
